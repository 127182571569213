/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-01-22",
    versionChannel: "nightly",
    buildDate: "2024-01-22T00:15:52.845Z",
    commitHash: "a1131cd70fe58b8127d8c791a72b32277abd18cd",
};
